@import './variables.scss';

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(10%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.currentRide {
  max-width: 100%;
}

.rideReady {
  background-color: $blue;
  min-height: 100vh;
  color: white;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  padding: 110px 0;
  .coreScore {
    color: white;
  }
  .rideSets {
    margin-bottom: 25px;
    @media (max-width: $mobile) {
      .cw, .ccw {
        label {
          display: none;
        }
      }  
    }
  }
  .message {
    animation: slideUp .5s;
  }
  .prompt {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    pointer-events: none;
    opacity: 0;
    transition: .3s;
    z-index: 7;
    button.secondary {
      background-color: rgba(0,0,0,0);
      border-color: white;
      color: white;
    }
    .icon {
      display: block;
      margin: 0 auto;
    }
    &.show {
      background-color: rgba(45,45,45,.9);
      opacity: 1;
      pointer-events: all;
    }
    p {
      margin: 20px auto;
      font-size: 24px;
      line-height: 32px;
      max-width: 450px;
      text-align: center;
      @media (max-width: 767px) {
        font-size: 18px;
        line-height: 22px;
        max-width: 80vw;
        margin: 10px auto;
      }
    }
  }
  &.started {
    footer {
      background-color: $blue;
    }
  }
  &.paused {
    background-color: $dark-gray;
  }
  &.started,
  &.paused {
    .rideSets {
      li {
        flex: 1;
      }
      .parameter {
        &.cw, &.ccw {
          @media (max-width: 400px) {          
            justify-content: flex-end;
          }          
        }
        &.spins {
          padding-left: 0;
          .number {
            margin-left: 0;
          }
        }
        input {
          height: 35px;
          width: 40px;
          text-align: center;
          font-size: 36px;
          position: relative;
          font-weight: 500;
          line-height: 36px;
          margin: 0px 10px;
          background-color: rgba(0,0,0,0);
          color: white;
          padding: 0;
          border: 0;
        }
      }
      &.editing {
        padding: 0;
        .parameter-input {
          @media (max-width: $mobile) {
            input {
              height: 60px;
            }
          }

          @media (max-width: $tablet) {
            .stepper {
              width: 15px;
              height: 15px;
              background-size: 4px;
              background-position: center left 6px;
              margin: 0;  
              display: block;
              &.less {
                margin-right: -5px; 
              }
              &.more {
                margin-left: -5px;
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
    footer {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      z-index: 6;
      .flexRow {
        display: flex;
        button {
          flex-shrink: 0;
          @media (max-width: $mobile) {
            width: 100px;
          }
        }
      }
      &.fixed {
        position: fixed;
      }
    }
  }

  .rideStatus {
    text-transform: uppercase;
    font-size: 14px;
    line-height: 36px;
    height: 36px;
    display: flex;
    font-weight: bold;
    span {
      display: inline-block;
      margin-right: 10px;
      font-size: 36px;
      font-weight: normal;
      line-height: 32px;
    }
  }

  footer {
    font-size: 24px;
    text-align: center;
    line-height: 28px;
    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 20px;
    }
    span {
      color: $green;
      text-transform: uppercase;
      font-weight: bold;
    }
    .timeout {
      opacity: 0;
      pointer-events: none;
      margin-top: -120px;
      transition: .5s;
      .button.secondary {
        color: white;
        border-color: white;
      }
      &.active {
        margin-top: 35px;
        pointer-events: all;
        display: block;
        opacity: 1;
      }
    }
  }
}
