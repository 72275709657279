@import './variables.scss';

@keyframes slideIn {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

.coreScore {
  text-transform: uppercase;
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-bottom: -20px;
  justify-content: center;
  color: $blue;
  .star {
    width: 15px;
    height: 15px;
    margin-left: 10px;
  }
  span {
    font-size: 24px;
    margin-left: 5px;
    color: $green;
    font-weight: bold;
  }
}

.rideConfig {
  padding-bottom: 180px;
  h2 {
    margin: 0;
    padding: 10px 0px;
    text-align: center;
    font-weight: normal;
    font-size: 24px;
    color: $blue;
    border-bottom: 1px solid $light-gray;
  }
  ol {
    margin: 0px;
    padding: 0;
    li {
      overflow: hidden;
      list-style-type: none;
      .set {
        border-bottom: 1px solid $light-gray;
        display: flex;
        padding: 30px 15px;
        line-height: 40px;
        justify-content: space-around;
        font-size: 18px;
        text-transform: uppercase;
        @media (max-width: $tablet) {
          align-items: center;
          font-size: 14px;
          padding: 10px;
        }
      }
      &.isNew .set {
        animation: slideIn .5s;
      }
      .parameter {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 40px;
        line-height: 20px;

        @media (max-width: $mobile) {
          .direction-label {
            display: none;
          }
        }
        @media (max-width: $tablet) {
          height: 70px;
        }
        input[type=checkbox] {
          display: none;
        }
        label {
          margin: 0 10px;
        }
        &.parameter-direction {
          cursor: pointer;
          .direction-label {
            line-height: 0;
            transition: line-height .3s;
            :first-child {
              opacity: 0;
              transition: opacity .3s;
            }
            &.direction-ccw-label {
              line-height: 1;
              :first-child {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
  footer {
    position: fixed;
    border-top: 1px solid $light-gray;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
  }
  @media (max-width: $tablet) {
    .stepper {
      display: none;
    }
    .delete {
      height: 25px;
      width: 25px;
    }
  }  
}

.delete {
  background-image: url('../img/x.svg');
  background-repeat: no-repeat;
  background-position: center center;
  height: 35px;
  width: 35px;
  border: 2px solid $light-gray;
  border-radius: 100%;
  pointer-events: none;
  opacity: 0;
  flex-shrink: 0;
  background-size: contain;
  &.active {
    opacity: 1;
    pointer-events: all;
    cursor: pointer;
  }
}
